import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import variables from "../components/GlobalVariables"
import Title from "../components/UI/Title"
import Selfie from "../images/me.jpg"

const AllMyLinksStyles = styled.div`
  margin: 10px auto;
  width: 100%;
  max-width: 370px;
`
const AllLinkStyles = styled.div`
  box-sizing: border-box;
  max-width: 350px;
  margin: 30px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background: var(--darkColor);

  button {
    box-sizing: border-box;
    background-color: ${variables.greyColor};
    font-family: var(--headerFont);
    color: ${variables.clearColor};
    border: ${variables.accentColor} 1px solid;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 30px;
    -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
    transition: box-shadow 300ms ease-in-out, color 500ms ease-in-out;
    padding: 10px 20px;
    width: 100%;
    transition: ease 0.2s;

    &:hover {
      color: ${variables.clearColor};
      border: ${variables.accentColor} 1px solid;
      box-shadow: 0 0 40px 40px ${variables.accentColor} inset;
      transition: ease 0.2s;
      transform: scale(1.15);
    }

    @media (max-width: ${variables.smallScreen}) {
    }
  }

  img {
    margin: 0 auto;
    border-radius: 100px;
    width: 130px;
  }
`

const AllLinks = [
  { title: "Website", url: "http://jimenez.tech/", id: 101 },
  { title: "Twitter", url: "https://twitter.com/Dann3rs", id: 104 },
  {
    title: "LinkedIn",
    url: "https://www.linkedin.com/in/djimenez017/",
    id: 102,
  },

  { title: "Github", url: "https://github.com/djimenez017", id: 103 },
  { title: "Twitch", url: "https://www.twitch.tv/danners2772", id: 108 },

  { title: "Blog", url: "https://jimenez.tech/blog", id: 105 },
  {
    title: "Facebook",
    url: "https://www.facebook.com/daniel.jimenez.184007",
    id: 106,
  },
  { title: "Instagram", url: "https://www.instagram.com/dann3r5/", id: 107 },
]

const allmylinks = () => {
  const AllLinkButtons = AllLinks.map(AllLinkButton => {
    return (
      <AllMyLinksStyles key={AllLinkButton.id}>
        <a href={AllLinkButton.url} target="_blank">
          <button className={"Button"}>
            {AllLinkButton.title.toUpperCase()}
          </button>
        </a>
      </AllMyLinksStyles>
    )
  })

  return (
    <AllLinkStyles>
      <SEO
        title="All my links"
        description="A directory of all of my social media sites where you can get in touch with me."
      />

      <img
        src={Selfie}
        alt="Daniel Jimenez, Front end developer, gamer, and music fan"
      />
      <br />
      <Title align={"center"}>Daniel Jimenez</Title>
      <p>Follow me on any of my other social media sites!</p>
      {AllLinkButtons}
    </AllLinkStyles>
  )
}

export default allmylinks
